import * as bootstrap from 'bootstrap'

// Contact Script
export function contactScript() {
    var Submitted = false;
    $('#contact-form').on('submit', function(event) 
    {
        if(Submitted == false)
        {
            Submitted = true;
            let $this = $('#contact-form');
            //Hides the form
            $this.addClass('d-none');
            //Appends a spinner to the parent of the form
            $this.parent().append('<div class="load-spinner text-center"><h1><svg class="pi-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M222.7 32.1c5 16.9-4.6 34.8-21.5 39.8C121.8 95.6 64 169.1 64 256c0 106 86 192 192 192s192-86 192-192c0-86.9-57.8-160.4-137.1-184.1c-16.9-5-26.6-22.9-21.5-39.8s22.9-26.6 39.8-21.5C434.9 42.1 512 140 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 140 77.1 42.1 182.9 10.6c16.9-5 34.8 4.6 39.8 21.5z"/></svg></h1></div>');
            let data = new FormData($this[0]);

            $.ajax(
            {
                type: "POST",
                url: $this.attr('action'),
                data: data,
                contentType: false,
                processData: false,
                cache: false
            })
            .done(function( msg ) 
            {
                var obj = JSON.parse( msg );
                $this.parent().find('.load-spinner').remove();
                //We determine if we need to return data to the clicked item
                if(obj.success)
                {
                    
                    $this.parent().append("<h2 class='text-left mb-20' id='contact-form-success' style='color:#000; font-size: 20px; font-style: normal; font-weight: 500;'>"+obj.message+"</h2>");
                    $this.remove();
                    
                }
                else
                {
                    $this.removeClass('d-none');
                    $this.prepend("<h2 class='text-left mb-20' style='color:#000; font-size: 20px; font-style: normal; font-weight: 500; font-family: 'Work Sans';'>"+obj.message+"</h2>");

                    // Useful try catch when someone is yet to put recaptcha in.
                    try {
                        if (grecaptcha)
                            grecaptcha.reset();
                    }
                    catch (err) {
                        console.error("Captcha not configured")
                    }
                }
                Submitted = false;
            });
        }
        
        //prevents form from submitting
        event.preventDefault();		  
        return false;
    });
}

// AJAX Request Handler
var activeRequests = {};
/**
 * 
 * @param jQuery $element 
 * @param string url 
 * @param array of callbacks success 
 * @param array of callbacks error 
 * @param string method 
 * @param string dataType 
 * @param hash $.ajax options
 */
export function ajaxResponse($element, url, data, success, error, method, dataType, overrideAjaxSettings) 
{
    if (!(data instanceof FormData))
    {
        data = Object.assign({}, data);
    }

    overrideAjaxSettings = Object.assign({}, overrideAjaxSettings);
    if(hasActiveRequest(url, data))
    {
        return;
    }

    $element['processing-request'] = true;
    if(typeof(success) == undefined)
    {
        success = [];
    }

    if(!Array.isArray(success))
    {
        var tempsuccess = success;
        success = [];
        success.push(tempsuccess);
    }

    if(typeof(error) == undefined)
    {
        error = [];
    }

    if(!Array.isArray(error))
    {
        var temperror = error;
        error = [];
        error.push(temperror);
    }

    if(typeof(method) == undefined)
    {
        method = "GET";
    }

    if(typeof(dataType) == undefined)
    {
        dataType = "json";
    }

    if(typeof(data) == undefined)
    {
        data = {};
    }
    // These are non-overrideable settings, if these need to be changed used the provided parameters
    var ajaxSettings = {
        context: $element,
        dataType: dataType,
        method: method,
        url: url,
        success: success,
        error: error,
        data: data,
        complete: function(){
            removeActiveRequest(url,data);
        }
    };
    overrideAjaxSettings = Object.assign(overrideAjaxSettings, ajaxSettings);
    makeActiveRequest(url, data);
    return $.ajax(overrideAjaxSettings);
}

function hasActiveRequest(url, data)
{
    var key = url + JSON.stringify(data);
    return activeRequests.hasOwnProperty(key) && activeRequests[key] == true;
}

function makeActiveRequest(url, data)
{
    var key = url + JSON.stringify(data);
    activeRequests[key] = true;
}

function removeActiveRequest(url, data)
{
    var key = url + JSON.stringify(data);
    activeRequests[key] = false;
}

// Scrolling Loader
export function infiniatePageLoadInit(options) {
    var requiredOptions = [
        "targetUrl", // Required, must provide a string
        "targetElement", // Required must provide a valid jquery selector
        "cellTemplate", // Required must return value compatible with templateEngine, by default a jquery selector
        "handleNextPageResponse" // Required must call this.render and provide the objects to be rendered (as an array)
    ];
    for(var i = 0; i < requiredOptions.length; i++) {
        if(!options.hasOwnProperty(requiredOptions[i])) {
            return undefined;
        }
    }

    var allowedToOverwrite = [
        "additionalParameters", // Function that returns an object 
        "cellEventHandlers", // Function that generates event handlers, no return needed
        "errorHandler", // Custom error handler, should accept the standard error reponses by ajax-request-handler
        "templateEngine", // by default will use internal template handler, if you overwrite this be sure your templates are compatible
        "loadTriggerHeight", // How much offset required to trigger a next page load
        "endOfResultsData", // If your endOfResultsTemplate requires data, use this function to gather and return an object
        "globalTemplateOptions", // Place translations here, this data is merged within templateEngine - if you overwrite templateEngine you need to support this yourself
        "preCellRenderHandler", // It is provided a jquery object, and MUST return a jquery object - is called before the cell is appended to the page
        "currentPage", // If your target already has data, you'll want to update this accordingly
        "totalResults", // If your target already has data, you'll want to update this accordingly
        "initialData", // Got data already? Put it here, be sure to set currentPage and totalResults - only the responses check that stuff
        "scrollTarget", // If we're not going to watch windows scroll offset, then specify it here with a valid jquery selector/object
        "context" // This will be the context that all non-core things are proxied (IE additionalParameters) if it's missing there will be no proxy
    ];

    var infiniteLoad = {
        targetUrl: undefined,
        cellTemplate: undefined,
        endOfResultsTemplate : undefined,
        currentPage: undefined,
        totalResults : 0,
        hasMorePages : true,
        loadTriggerHeight: 400,
        requestingNextPage : false,
        responseIsEmpty: false,
        globalTemplateOptions : {},
        hasInitialisedCurrentPage : false,
        scrollTarget : undefined,
        documentTarget : undefined,
        currentRequest : undefined,
        isVisible: false,
        callContextMethod: function(methodToCall) {
            var slicedArgs = Array.prototype.slice.call(arguments, 1);
            if(typeof(this.context) != "undefined") {
                return methodToCall.call(this.context, ...slicedArgs);
            } else {
                return methodToCall(...slicedArgs);
            }
        },
        buildUrl: function(targetUrl, args) {
            return targetUrl + "?" + $.param(args);
        },
        endOfResultsData : function() { return {}; },
        additionalParameters: function() { return {}; },
        cellEventHandlers: function() { return; },
        preCellRenderHandler : function($targetCell) { return $targetCell; },
        _handleNextPageResponse : function(obj,textStatus, jqXHR) {
            this.getTarget().trigger('scrolling.loader.next_page_loaded', this);
            this.requestingNextPage = false;
            this.incrementCurrentPage();
            if(jqXHR.status == 204) {
                this.hasMorePages = false;
                this.responseIsEmpty = true;
                var $target = this.getTarget();
                if(typeof(this.endOfResultsTemplate) != 'undefined')
                    $target.append(this.templateEngine(this.endOfResultsData(), this.endOfResultsTemplate));
                this.getTarget().trigger('scrolling.loader.nomore', this);
                if(this.responseIsEmpty)
                    if(this.totalResults == 0)
                        this.getTarget().trigger('scrolling.loader.empty', this);
                return;
            }

            if(obj.hasOwnProperty('total_results')) {
                this.totalResults = obj['total_results'];
            } else {
                this.totalResults += Object.keys(obj).length;
            }

            this.handleNextPageResponse(obj);
        },
        handleNextPageResponse: function() { return; },
        postNextPageResponseCheck : function() { 
            if(this.checkLoadMoreResults()) {
                this.requestNextPage();
            }
        },
        requestNextPageParameters : function() {
            return Object.assign({ page:this.getNextPage() }, this.callContextMethod(this.additionalParameters));
        },
        getNextPage : function() {
            if(!this.hasInitialisedCurrentPage) {
                if(typeof(this.currentPage) == "undefined") {
                    this.currentPage = 0;
                }
                return this.currentPage;
            }
            return this.currentPage + 1;
        },
        incrementCurrentPage : function() {
            if(typeof(this.currentPage) == "undefined") {
                this.currentPage = 0;
            } else if(this.hasInitialisedCurrentPage) {
                this.currentPage++;
            }
            this.hasInitialisedCurrentPage = true;
        },
        errorHandler: function() { return; },
        _errorHandler : function(error) {
            // DO SOMETHIHNG?
            this.requestingNextPage = false;
        },
        _requestNextPage : function (targetUrl, handleSuccess, handleError) { 
            if(this.requestingNextPage) {
                return;
            }
            
            this.currentRequestUrl = targetUrl;
            this.requestingNextPage = true;
            this.currentRequest = ajaxResponse(this, targetUrl, undefined, handleSuccess, handleError, "GET", "json");
        },
        requestNextPage : function() {
            this._requestNextPage(this.buildUrl(this.targetUrl, this.requestNextPageParameters()), this._handleNextPageResponse, [this.errorHandler, this._errorHandler]);
        },
        abortCurrentRequest : function() {
            if(typeof(this.currentRequest) != "undefined") {
                var request = this.currentRequest;
                request.abort();
            }
            this.requestingNextPage = false;
            this.currentRequest = undefined;
        },
        templateEngine : function(obj, template) {
            if(typeof(template) == "undefined") {
                return "";
            }
            return this.nano(template, obj);
        },
        nano: function(template, data) {
            return template.replace(/\{\{([\w\.]*)\}\}/g, function(str, key) {
            var keys = key.split("."), v = data[keys.shift()];
            for (var i = 0, l = keys.length; i < l; i++) v = v[keys[i]];
            return (typeof v !== "undefined" && v !== null) ? v : "";
            });
        },
        getTarget: function() {
            return $('body').find(this.targetElement);
        },
        render: function(objsToRender) {
            if(Object.keys(objsToRender).length == 0) {
                return;
            }
            var $target = this.getTarget();
            for(var key in objsToRender) {
                if(objsToRender.hasOwnProperty(key)) {
                    var cellHtml = this.templateEngine(Object.assign(objsToRender[key], this.globalTemplateOptions), this.cellTemplate);
                    cellHtml = $(this.callContextMethod(this.preCellRenderHandler, $(cellHtml), objsToRender[key]))[0].outerHTML;
                    $target.append(cellHtml);
                }
            }
            this.getTarget().trigger('scrolling.loader.finished_render', this);
            this.postNextPageResponseCheck();
        },
        clearRender : function() {
            var $target = this.getTarget();
            $target.html('');
            this.currentPage = 0;
            this.totalResults = 0;
            this.hasInitialisedCurrentPage = false;
            this.hasMorePages = true;
            this.responseIsEmpty = false;
            this.getTarget().trigger('scrolling.loader.clear_render', this);
        },
        checkLoadMoreResults : function() {
            var loadMore = false;
            var documentScrollheight = 0;
            try {
                if(typeof(this.documentTarget[0].scrollHeight) == "undefined") {
                    documentScrollheight = this.documentTarget.height();
                } else {
                    documentScrollheight = this.documentTarget[0].scrollHeight;
                }                
            } catch (error) {
                
            }
            if(this.scrollTarget.scrollTop() >= (documentScrollheight - this.scrollTarget.height() - this.loadTriggerHeight)) {
                loadMore = true;
            }
            return loadMore;
        },
        initEventHandlers: function() {
            this.scrollTarget.scroll(
                $.proxy(function() {
                    if(this.checkLoadMoreResults() && this.hasMorePages) {
                        this.requestNextPage();
                    }

                },
                this)
            );
        },
        renderCell(selector, obj, template) {
            var $target = this.getTarget();
            var $cell = $target.find(selector);
            if($cell.length == 0){
                return;
            }
            if(typeof(template) == "undefined") {
                template = this.cellTemplate;
            }
            var cellHtml = this.templateEngine(obj, template);
            cellHtml = this.preCellRenderHandler($(cellHtml))[0].outerHTML;
            $cell.replaceWith(cellHtml);
        },
        init : function() {
            this.getTarget().trigger('scrolling.loader.init', this);
            if(typeof(this.initialData) != "undefined" && (Array.isArray(this.initialData) && this.initialData.length > 0)) {
                this.totalResults = this.initialData.length;
                this.render(this.initialData);
                this.initialData = undefined;
            } else {
                this.requestNextPage();
            }
            this.initEventHandlers();
            this.cellEventHandlers();
        }
    }

    var allValidKeys = requiredOptions.concat(allowedToOverwrite);
    for(var key in options) {
        if(allValidKeys.includes(key)) {
            infiniteLoad[key] = options[key];
        }
    }

    if(typeof(infiniteLoad.scrollTarget) == "undefined") {
        infiniteLoad.scrollTarget = $(window);
        infiniteLoad.documentTarget = $(document);
    } else if(!(infiniteLoad.scrollTarget instanceof jQuery)) {
        infiniteLoad.scrollTarget = $(infiniteLoad.scrollTarget);
        infiniteLoad.documentTarget = infiniteLoad.scrollTarget;
    }

    if(typeof(infiniteLoad.documentTarget) == "undefined") {
        infiniteLoad.documentTarget = infiniteLoad.scrollTarget;
    }

    return infiniteLoad;
}


/**
*  @function email - Renders email link in specific DOM containers. Should be called once per classname 
*  where the email links are to be rendered with different options (with or without link)
*
*  @param name - First half of email address before @ symbol
*  @param domain - Second half of email address after @ symbol
*  @param withlink - dictates if email address requires mailto link
*  @param targetClass - dictates which elements it will be rendered on
*  @param customText - the text that will appear that is linked to the mailto
*/
export function email(name, domain, withlink, targetClass, customText) {
    let addr = name + '@' + domain;
    var idEmail = document.getElementsByClassName(targetClass);
    for (var x = 0; x < idEmail.length; x++) {
        var item = idEmail[x];
        if (withlink) {
            var a = document.createElement('a');
            a.setAttribute('href', 'mailto:' + addr);
            if (customText) {
                a.appendChild(document.createTextNode(customText));
            }
            else {
                a.appendChild(document.createTextNode(addr));
            }
            item.appendChild(a);
        }
        else {
            var p = document.createElement('p');
            if (customText) {
                p.appendChild(document.createTextNode(customText));
            }
            else {
                p.appendChild(document.createTextNode(addr));
            }
            item.appendChild(p);
        }
    }
}
export function init(){
    console.log('todo');
}

export function test() {
    console.log('test');
}